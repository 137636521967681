<template>
    <div class="app-indexpage growpage u-p-30 u-rela">
        <img
                class="imgbg imgbg5 u-abso"
                src="../../assets/images/applet/index/bg5.png"
                alt=""
        />
        <div class="bggray radius-60">
            <!-- 生长过程 -->
            <div class="u-p-22 u-p-t-40 u-rela">
                <div class="title u-flex">
                    <h2 class="u-flex-1 u-font-38 u-main-color u-m-b-0">生长过程</h2>
                    <i class="iconfont iconcheck_fill green2 u-font-30 u-m-r-10"></i>
                    <span class="green2 u-font-30">可追溯</span>
                </div>
                <p class="u-tips-color u-font-32 u-m-t-20">
                    当地政府通过数字农业系统对红糖加工以及原材甘蔗种植进行全方位监管
                </p>
                <div class="panel u-rela index-1">
                    <div class="u-text-center">
                        <div
                                class="dotline u-flex u-flex-col u-row-center u-font-36 green4 auto"
                        >
                            <span class="blod">{{list[type].title}}</span>
                            <span class="u-font-28">{{list[type].date}}</span>
                        </div>
                    </div>

                    <div class="u-rela u-m-t-60 imgbox">
                        <img
                                class="block img100"
                                :src="list[type].img1"
                        />
                        <span class="u-abso u-font-24 white">
              {{list[type].text1t}} <br/> {{list[type].text1b}}
            </span>
                    </div>
                    <div v-show="list[type].img2!=undefined" class="u-rela u-m-t-30 imgbox">
                        <img
                                class="block img100"
                                :src="list[type].img2"
                        />
                        <span class="u-abso u-font-24 white">
             {{list[type].text2t}} <br/>{{list[type].text2b}}
            </span>
                    </div>
                    <div class="u-text-center u-m-t-40">
                        <div
                                class="dotline u-flex u-flex-col u-row-center u-font-36 green4 auto"
                        >
                            <span class="blod">操作记录</span>
                        </div>
                    </div>
                    <!-- 操作人 -->
                    <div v-for="(item,i) in list[type].list" class="panel caozuoren u-m-t-40">
                        <div class="u-flex u-row-between u-font-28">
                            <span class="u-content-color">操作人：{{item.name}}</span>
                            <span class="white bggreen2 u-p-6 u-p-l-20 u-p-r-20 radius-5">{{item.date}}</span>
                        </div>
                        <viewer :images="item.img">
                            <div class="u-flex u-row-between u-m-t-30">

                                <div v-for="(itemi,index) in item.img" class="u-flex u-flex-col img_list">
                                    <img :src="itemi" :data-source="itemi" :key="index"/>
                                </div>

                            </div>
                        </viewer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "",
        data() {
            return {
                type: 0,
                list: {
                    1: {
                        'title': '开始种植',
                        'date': '2023年04月03日',
                        'img1': require('../../assets/images/applet/index/szgc/A.png'),
                        'text1t': ' 温度：21℃ 空气湿度：14.6% 大气压力：102.3Pa ',
                        'text1b': '风速：1m/s 风向：南风 降雨量：0mm ',
                        'list': [
                            {
                                'name': '农民朋友',
                                'date': '2023-04-03',
                                'img': [require('../../assets/images/applet/index/szgc/A1.jpeg'), require('../../assets/images/applet/index/szgc/A2.jpeg'), require('../../assets/images/applet/index/szgc/A3.jpeg')]
                            }
                        ]
                    },
                    2: {
                        'title': '萌芽期',
                        'date': '2023年04月04日-2023年04月20日',
                        'img1': require('../../assets/images/applet/index/szgc/B.png'),
                        'text1t': ' 温度：21℃ 空气湿度：14.6% 大气压力：102.3Pa ',
                        'text1b': '风速：1m/s 风向：南风 降雨量：0mm ',
                        'list': [
                            {
                                'name': '农民朋友',
                                'date': '2023-04-06',
                                'img': [require('../../assets/images/applet/index/szgc/B1.jpeg'), require('../../assets/images/applet/index/szgc/B2.jpeg'), require('../../assets/images/applet/index/szgc/B3.jpeg')]
                            }
                        ]
                    },
                    3: {
                        'title': '幼苗期',
                        'date': '2023年04月21日-2023年06月09日',
                        'img1': require('../../assets/images/applet/index/szgc/C1.png'),
                        'text1t': ' 温度：21℃ 空气湿度：14.6% 大气压力：102.3Pa ',
                        'text1b': '风速：1m/s 风向：南风 降雨量：0mm ',
                        'img2': require('../../assets/images/applet/index/szgc/C2.png'),
                        'text2t': ' 温度：21℃ 空气湿度：14.6% 大气压力：102.3Pa ',
                        'text2b': '风速：1m/s 风向：南风 降雨量：0mm ',
                        'list': [
                            {
                                'name': '农民朋友',
                                'date': '2023-04-30',
                                'img': [require('../../assets/images/applet/index/szgc/C1.jpeg'), require('../../assets/images/applet/index/szgc/C2.jpeg'), require('../../assets/images/applet/index/szgc/C3.jpeg')]
                            }
                        ]
                    },
                    4: {
                        'title': '分蘖期',
                        'date': '2023年06月10日-2023年07月15日',
                        'img1': require('../../assets/images/applet/index/szgc/D1.png'),
                        'text1t': ' 温度：21℃ 空气湿度：14.6% 大气压力：102.3Pa ',
                        'text1b': '风速：1m/s 风向：南风 降雨量：0mm ',
                        'img2': require('../../assets/images/applet/index/szgc/D2.png'),
                        'text2t': ' 温度：21℃ 空气湿度：14.6% 大气压力：102.3Pa ',
                        'text2b': '风速：1m/s 风向：南风 降雨量：0mm ',
                        'list': [
                            {
                                'name': '农民朋友',
                                'date': '2023-06-17',
                                'img': [require('../../assets/images/applet/index/szgc/D1.jpeg'), require('../../assets/images/applet/index/szgc/D2.jpeg'), require('../../assets/images/applet/index/szgc/D3.jpeg')]
                            }
                        ]
                    },
                    5: {
                        'title': '伸长期',
                        'date': '2023年07月16日-2023年10月08日',
                        'img1': require('../../assets/images/applet/index/szgc/E1.png'),
                        'text1t': ' 温度：21℃ 空气湿度：14.6% 大气压力：102.3Pa ',
                        'text1b': '风速：1m/s 风向：南风 降雨量：0mm ',
                        'img2': require('../../assets/images/applet/index/szgc/E2.png'),
                        'text2t': ' 温度：21℃ 空气湿度：14.6% 大气压力：102.3Pa ',
                        'text2b': '风速：1m/s 风向：南风 降雨量：0mm ',
                        'list': [
                            {
                                'name': '农民朋友',
                                'date': '2023-07-27',
                                'img': [require('../../assets/images/applet/index/szgc/E1.jpeg'), require('../../assets/images/applet/index/szgc/E2.jpeg'), require('../../assets/images/applet/index/szgc/E3.jpeg')]
                            }
                        ]
                    },
                    6: {
                        'title': '成熟期',
                        'date': '2023年10月09日-2023年11月30日',
                        'img1': require('../../assets/images/applet/index/szgc/F.png'),
                        'text1t': ' 温度：21℃ 空气湿度：14.6% 大气压力：102.3Pa ',
                        'text1b': '风速：1m/s 风向：南风 降雨量：0mm ',
                        'list': [
                            {
                                'name': '农民朋友',
                                'date': '2023-07-27',
                                'img': [require('../../assets/images/applet/index/szgc/u0.jpeg'), require('../../assets/images/applet/index/szgc/u1.jpeg'), require('../../assets/images/applet/index/szgc/u2.jpg')]
                            }
                        ]
                    },

                }
            };
        },
        mounted() {
            this.type = this.$route.query.type;
        },
        methods: {},
    };
</script>
<style lang="scss">
    @import "../../assets/css/applet.scss";
</style>
